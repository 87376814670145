import type { Utente } from '@apb/database'
import classNames from 'classnames'
import { Suspense, useState } from 'react'
import useOpenPopup from 'use-open-popup'

import UsersTable, { BaseUtente, UsersTableProps } from '../containers/UsersTable'
import useSearch from '../hooks/useSearch'
import AggiungiUtenteCard from './AggiungiUtenteCard'
import Card from './Card'
import SearchInput from './SearchInput'

type RequiredUtente = BaseUtente
const searchOpts = { keys: ['user.nome', 'user.cognome', 'dipartimento'] }
export default function UsersTableCard<T extends RequiredUtente>({
  users,
  parentId,
  aziendaId,
  mutate,
  disableEditing,
  extraColumns,
  addRole,
  title,
  onRowClick
}: {
  users: T[]
  parentId: number | null
  aziendaId: number
  mutate: () => void
  disableEditing?: boolean
  extraColumns?: UsersTableProps<T>['extraColumns']
  addRole: Utente['role']
  title: string
  onRowClick?: (userData: T) => void
}): JSX.Element {
  const [query, setQuery] = useState<string>('')
  const results = useSearch(users, query, searchOpts)

  const openPopup = useOpenPopup(({ handleClose }) => (
    <Suspense fallback={<div tabIndex={0}> </div>}>
      <AggiungiUtenteCard
        role={addRole}
        parentId={parentId}
        aziendaId={aziendaId}
        handleClose={() => {
          mutate()
          handleClose()
        }}
      />
    </Suspense>
  ))

  return (
    <Card>
      <div className={classNames('flex gap-3 flex-row mb-2 whitespace-nowrap')}>
        <h3 className="flex-grow m-0">
          {title} <span className="text-sm text-gray-400 font-medium">({users.length})</span>
        </h3>
        <SearchInput query={query} setQuery={setQuery} />
        {!disableEditing && (
          <button className="btn-primary" onClick={openPopup}>
            <i className="fas fa-plus" /> Aggiungi
          </button>
        )}
      </div>
      {users.length > 0 ? (
        results.length > 0 ? (
          <UsersTable
            utenti={results}
            disableEditing={disableEditing}
            mutate={mutate}
            showBoard
            extraColumns={extraColumns}
            onRowClick={onRowClick}
          />
        ) : (
          <p className="text-center m-1 mt-3">
            Nessun risultato.{' '}
            <a
              onClick={() => {
                setQuery('')
              }}
            >
              Annulla la ricerca
            </a>
          </p>
        )
      ) : (
        <p className="text-center m-1 mt-3">
          <a onClick={openPopup}>Aggiungi</a> un{' '}
          {((role: Utente['role']) => {
            switch (role) {
              case 'COLLABORATORE':
                return 'collaboratore'
              case 'LINE_MANAGER':
                return 'line manager'
              case 'DIREZIONE':
                return 'membro della direzione'
              case 'HR':
                return 'membro delle risorse umane'
              case 'MANAGER':
                return 'manager'
              case 'DIREZIONE_GENERALE':
                return 'direttore generale'
              case 'ADMIN':
                return 'admin'
            }
          })(addRole)}
        </p>
      )}
    </Card>
  )
}
