import UserPath from '@apb/database/UserPath'
import classNames from 'classnames'
import { groupBy } from 'lodash'
import moment from 'moment'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { z } from 'zod'

import AziendaImage from '../components/AziendaImage'
import UsersTableCard from '../components/UsersTableCard'
import { useIsSelf } from '../hooks/useHasRole'
import { trpc } from '../trpc'
import TreeView from './TreeView'
import BoardButton from '../components/BoardButton'
import { Utente } from '@apb/database/schema'
import { EditUtentePopup } from '../components/EditUtentePopup'
import { DeleteUtentePopup } from '../components/DeleteUtentePopup'
import useOpenPopup from 'use-open-popup'
import SpostaUtentePopup from '../components/SpostaUtentePopup'
import ProtectedView from './ProtectedView'
import { formatRole } from '../helpers'
import { useNotifications } from '../atoms/notifications'

const zodViewType = z.enum(['c', 's', 't']).default('s')
type View = z.infer<typeof zodViewType>

type BaseUtente = Pick<
  Utente,
  'id' | 'nome' | 'cognome' | 'dipartimento' | 'email' | 'emailSent' | 'aziendaId' | 'role' | 'path' | 'parentId'
>
function UtenteOptionsButtons({ utente, refetch }: { utente: BaseUtente; refetch: () => void }) {
  const { createNotification } = useNotifications()
  const { mutateAsync: mutateUpdate } = trpc.utenti.update.useMutation({
    onSettled: refetch,
    onError: err => {
      if (err.data?.code === 'BAD_REQUEST' && err.message.startsWith('TOO_DIR_GEN')) {
        createNotification({
          style: 'error',
          icon: 'fas fa-times',
          title: 'Spostamento non valido',
          description: "L'azienda non può avere più di un direttore generale"
        })
      }
    }
  })
  const router = useRouter()
  const { mutateAsync: mutateDelete } = trpc.utenti.delete.useMutation({
    onSuccess() {
      router.back()
    }
  })

  const editPopup = useOpenPopup(
    useCallback(
      ({ handleClose }: { handleClose: () => void }) => (
        <EditUtentePopup
          utente={utente}
          refresh={refetch}
          handleClose={handleClose}
          onSubmit={async data => {
            await mutateUpdate({
              utenteId: utente.id,
              data: { ...data, email: data.email.length > 0 ? data.email : null }
            })
            handleClose()
          }}
        />
      ),
      [utente, refetch, mutateUpdate]
    )
  )
  const spostaPopup = useOpenPopup(
    useCallback(
      ({ handleClose }: { handleClose: () => void }) => (
        <SpostaUtentePopup
          utente={utente}
          onSubmit={async ({ parentId, role }) => {
            await mutateUpdate({
              utenteId: utente.id,
              data: { parentId, role }
            })
            handleClose()
          }}
          handleClose={handleClose}
        />
      ),
      [utente, mutateUpdate]
    )
  )
  const deletePopup = useOpenPopup(
    useCallback(
      ({ handleClose }: { handleClose: () => void }) => (
        <DeleteUtentePopup
          onSubmit={async () => {
            await mutateDelete({
              utenteId: utente.id
            })
            handleClose()
          }}
          handleClose={handleClose}
        />
      ),
      [utente, mutateDelete]
    )
  )

  return (
    <div className="undertitle-buttons">
      <button onClick={editPopup} className="btn">
        <i className="fas fa-edit" />
        <span className="btn-label">Modifica</span>
      </button>
      <ProtectedView keys={['ADMIN', 'HR']}>
        <button onClick={spostaPopup} className="btn">
          <i className="fas fa-person-walking-arrow-right" />
          <span className="btn-label">Sposta</span>
        </button>
      </ProtectedView>
      <ProtectedView keys={['ADMIN', 'HR']}>
        <button onClick={deletePopup} className="btn btn-danger">
          <i className="far fa-trash-alt" />
          <span className="btn-label">Elimina</span>
        </button>
      </ProtectedView>
    </div>
  )
}

export default function LineManagerView({ lineManagerId }: { lineManagerId: number }) {
  const router = useRouter()

  const selectedView = useMemo(() => zodViewType.parse(router.query.v), [router.query.v])
  const setView = useCallback(
    (v: View) => {
      router.push({ query: { ...router.query, v } })
    },
    [router]
  )

  const [data, { refetch }] = trpc.utenti.get.useSuspenseQuery({
    utenteId: lineManagerId,
    withBoard: 'METADATA',
    withChildren: selectedView === 's' ? 'ONLY_DIRECT' : 'ALL',
    withChildrenBoard: 'METADATA'
  })

  // const userPath = useMemo(() => new UserPath(data), [data])

  const children = useMemo(() => {
    if (!data.children) return []
    const map = new Map(data.children.map(c => [c.id, c]))

    return data.children.map(c => {
      const parentId = new UserPath(c).parentId
      if (parentId === null)
        throw new Error(`User without parentId in line manager view: lmId={data.id} utenteId=${c.id}`)
      return {
        ...c,
        parentId,
        parent: parentId === lineManagerId ? data : map.get(parentId) ?? null
      }
    })
  }, [data, lineManagerId])

  const grouped = useMemo(() => groupBy(data.children, c => c.role), [data.children])

  const isSelf = useIsSelf(lineManagerId)

  if (!data) return null
  if (!data.azienda) throw new Error(`User without azienda in line manager view: utenteId=${lineManagerId}`)

  return (
    <div>
      <Head>
        <title>
          {data.nome} {data.cognome} Line Manager - Agile Personal Board
        </title>
      </Head>

      <div className="p-4 pb-5 flex gap-4 items-start container-std">
        <div className="flex-grow">
          <h2 className="mb-0">{data.azienda.nome}</h2>
          {isSelf ? (
            <p className="font-medium mt-2">
              Benvenuto/a {data.nome} {data.cognome}
            </p>
          ) : (
            <p className="font-medium">
              {data.nome} {data.cognome}
            </p>
          )}
          <p className="text-gray-400 text-sm mb-3">
            {formatRole(data.role)} &#x2022;{' '}
            {data.email !== null && data.email.length > 0 && <>{data.email} &#x2022; </>}
            {data.dipartimento !== null && data.dipartimento.length > 0 && <>{data.dipartimento} &#x2022; </>}
            {moment().format('LL')}
          </p>
          <div className="flex gap-1 text-sm">
            <BoardButton userId={data.id} boardMetadata={data.boardMetadata} className="mr-1" />
            {!isSelf && <UtenteOptionsButtons utente={data} refetch={refetch} />}
          </div>
        </div>
        <AziendaImage aziendaId={data.azienda.id} />
      </div>

      <div className="text-right mb-3 container-std">
        <span className="text-sm text-gray-500 mr-2">Vista:</span>
        <button
          title="Separata"
          className={classNames(
            'btn-icon-sm hover:bg-gray-200 p-2 pl-3 rounded-r-none border-2 hover:border-gray-300',
            selectedView === 's' && 'bg-gray-200'
          )}
          onClick={() => setView('s')}
        >
          <i className="fas fa-columns" />
        </button>
        <button
          title="Combinata"
          className={classNames(
            'btn-icon-sm hover:bg-gray-200 p-2 rounded-none border-2 border-x-transparent hover:border-gray-300',
            selectedView === 'c' && 'bg-gray-200'
          )}
          onClick={() => setView('c')}
        >
          <i className="fas fa-table" />
        </button>
        <button
          title="Ad albero"
          className={classNames(
            'btn-icon-sm hover:bg-gray-200 p-2 pr-3 rounded-l-none border-2 hover:border-gray-300',
            selectedView === 't' && 'bg-gray-200'
          )}
          onClick={() => setView('t')}
        >
          <i className="fas fa-sitemap" />
        </button>
      </div>

      {selectedView === 'c' && (
        <div className="container-std">
          <UsersTableCard
            users={children}
            parentId={lineManagerId}
            aziendaId={data.azienda.id}
            mutate={refetch}
            disableEditing={false}
            extraColumns={{
              isManager: {
                render: c =>
                  c.role === 'MANAGER' ? (
                    <div className="mx-2 whitespace-nowrap text-gray-500">
                      <i className="fas fa-check text-green-500" /> Manager
                    </div>
                  ) : null,
                minWidth: true
              },
              isUnder: {
                name: 'Sotto a',
                render: c =>
                  c.parent ? (
                    <span>
                      {c.parent.nome} {c.parent.cognome}
                    </span>
                  ) : null
              }
            }}
            addRole="COLLABORATORE"
            title="Manager e Collaboratori"
          />
        </div>
      )}

      {selectedView === 's' && (
        <div className="container-std">
          <UsersTableCard
            users={grouped.MANAGER ?? ([] as never)}
            parentId={lineManagerId}
            aziendaId={data.azienda.id}
            mutate={refetch}
            disableEditing={false}
            onRowClick={c => {
              router.push(`/lineManager/${c.id}`)
            }}
            addRole="MANAGER"
            title="Manager"
          />

          <div className="h-6"></div>

          <UsersTableCard
            users={grouped.COLLABORATORE ?? []}
            parentId={lineManagerId}
            aziendaId={data.azienda.id}
            mutate={refetch}
            disableEditing={false}
            addRole="COLLABORATORE"
            title="Collaboratori"
          />
        </div>
      )}

      {selectedView === 't' && (
        <div className="overflow-x-a  uto">
          <TreeView utenti={children} rootUser={data} refetch={refetch} />
        </div>
      )}
    </div>
  )
}
